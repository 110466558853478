import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../../components/layout'
import Breadcrumbs from '../../components/sections/Breadcrumbs';
import Default from '../../components/products/Default';

export default ({data}) => {
    const product = data.product
    return (
        <Layout>
            <Breadcrumbs />
            <Default  product={product} />
        </Layout>
    )
}


export const query = graphql`
query($id: String!) {
    product: contentfulProduct(id: {eq: $id}) {
        name
        id
        price
        descriptions {
          descriptions
        }
        specs {
          specs
        }
        featuredImage {
          file {
            url
          }
        }
        images {
          file {
            url
          }
        }
    }
}
`