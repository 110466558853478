import React from 'react'
import {Link} from 'gatsby'
import Img from "gatsby-image"


const ProductItems = ({title, product}) => (
  <div className="mb-8 md:mb-24">
    <h2 className="text-4xl font-extrabold text-center uppercase">{title}</h2>
    <div className="flex flex-wrap">
        {related.map((product) => (
          <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-8" key={product.node.id}>
            <Link to={`/products/${product.node.slug}/`}>
              <div className="product-item rounded h-full text-center relative overflow-hidden">
              {product.node.featured_media.localFile.childImageSharp.fluid && <Img imgStyle={{objectFit: "cover"}}  className="max-w-full w-full max-h-xs product-item__thumbnail mx-auto block" alt={product.node.featured_media.alt_text} fluid={product.node.featured_media.localFile.childImageSharp.fluid} /> }
              <div className="product-item__overlay absolute top-0 left-0 w-full h-full"></div>
              {product.node.title &&
                <div className="product-item__text absolute w-full p-4 bottom-0 text-white">
                    <h2 className="leading-tight mb-0 mb-2 font-normal text-lg" dangerouslySetInnerHTML={{__html: product.node.title}}/>
                    <div className="text-sm uppercase no-underline font-medium text-white">
                    View product
                    </div>
                </div>
              }
              </div>
            </Link>
          </div>))
        }
    </div>
  </div>
)

export default ProductItems

