// This template is used for products that are not hoppings products
import React from 'react'

import TabbedInfo from './utilites/tab'
import ImageGalleryDefault from './utilites/product-images-default'
import MainInfo from './utilites/mainspecs'
import RelatedItem from './components/RelatedItem'
import Cta from '../sections/Cta'
import Emailer from '../sections/Emailer'

class Default extends React.Component  {
    constructor(props) {
        super(props)
        this.state = { 
            title: props.product.name,
            mainImage: {
                url: props.product.featuredImage.file.url,
                alt: `${props.product.name} Main Image`
            },
            images: props.product.images,
            price: props.product.price,
            desc: props.product.descriptions.descriptions,
            spec: props.product.specs.specs,
            related: null,
            checkbox: 0,
        }
    }

   
    updateImage = (id) => {
        this.setState({
            mainImage: {
                url: this.state.images[id].file.url,
                alt: `${this.state.title} Main Image`,
            },
        })
    }

    render() {
        
        return (
            <div  className="container mx-auto">
                <div className="mb-16 md:mb-24">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 md:pr-12 lg:pr-24">
                            <ImageGalleryDefault 
                                toggle={this.updateImage}
                                thumbnails={this.state.images}
                                mainImageUrl={this.state.mainImage.url}
                                mainImageAlt={this.state.mainImage.alt} 
                            />                    
                        </div>
                        <div className="w-full md:w-1/2 md:pl-8">
                            {this.state.title && <h1 className="text-4xl leading-normal">{this.state.title}</h1>}
                            <MainInfo price={this.state.price} />
                            <div className="product__info">
                                <TabbedInfo desc={this.state.desc} info={this.state.spec} />
                            </div>
                        </div>
                    </div>
                </div>   
                {this.state.related && <RelatedItem title="Related Products" items={this.state.related} /> }
                <Cta />
                <Emailer />
            </div>        
        )
    }
}

export default Default